import React, {Fragment, useEffect} from "react";
import Container from "@material-ui/core/Container";
import firebase from "firebase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {Bar, BarChart, CartesianGrid, Cell, Legend, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import PLUS_COLORS from "./PlusColors";

const getColorIndexFromTeam = (teams,name) => {
    return teams.map((team) => team.name).sort().indexOf(name);
};

function TeamGraph({id}) {
    const query = firebase.firestore().collection(`challenges`).doc(id).collection('teams');

    const [teams, loading, error] = useCollectionData(query);
    const [sortedTeams,setSortedTeams] = React.useState(null);

    return(<Fragment>
        {teams && <ResponsiveContainer  width='100%' aspect={16.0/9.0}><BarChart
            width={500}
            height={300}
            data={teams.map((team)=>({
                name: team.name, score: team.score
            }))}
            margin={{
                top: 5, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" stroke="white"/>
            <YAxis stroke="white"/>
            <Bar dataKey="score">
                {
                    teams.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={PLUS_COLORS[getColorIndexFromTeam(teams,entry.name)]}/>
                    ))
                }
            </Bar>
        </BarChart></ResponsiveContainer>}
    </Fragment>);
}

export default TeamGraph;