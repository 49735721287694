import firebase from "firebase/app";
import React, {Fragment} from "react";
import {useCollectionData} from "react-firebase-hooks/firestore";


function ShowUrl({company}) {
    const query = firebase.firestore().collection(`companies`).doc(company).collection('menu');

    const [menu, loading, error] = useCollectionData(query,);


    return (<Fragment>{menu&&<iframe width="100%"  height="100%" frameBorder={0} src={menu[0].url}></iframe>}</Fragment>);
}

export default ShowUrl;