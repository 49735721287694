import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import * as moment from 'moment';
import 'moment-timezone';
import {firestore} from "firebase";
import TeamGraph from "../components/TeamGraph";
import ScoreTimeline from "../components/ScoreTimeline";
import Box from "@material-ui/core/Box";
import ShowUrl from "./ShowUrl";

const useStyles = makeStyles( theme=>({
    root: {
        display: 'flex',
        background: "#00A4CF",
        color: "white"
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        padding: 60
    },
    info: {
        textAlign: "center"
    },
    appBarSpacer: theme.mixins.toolbar,
}));

function RenderPage({spec}) {
    const classes = useStyles();

    console.log(moment().tz('Europe/Oslo').startOf('month').toDate());

    const startMonth = firebase.firestore.Timestamp.fromDate(moment().tz('Europe/Oslo').startOf('month').toDate());

    const query = firebase.firestore().collection(`challenges`).where("company","==",spec.company).where("startMonth","==",startMonth);

    const [challenges, loading, error] = useCollectionData(query,{idField:'id'});



    return (<div className={classes.root}>
            {loading &&
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={classes.content}
                />}
            {error && <Grid item xs={12}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={classes.content}
                ><Typography variant="h4">Error: {error.message}</Typography></Grid>
            </Grid>}
            {challenges &&
                <Grid container>
                <Grid item xs={8}>

                <Grid container spacing={3} className={classes.content}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} className={classes.info}>
                                <Typography variant="h4">{challenges[0].title}</Typography>
                                <Typography variant="h6">{challenges[0].tagline}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TeamGraph id={challenges[0].id}/>
                            </Grid>
                        </Grid>
                    <Grid item xs={12}>
                        <ScoreTimeline id={challenges[0].id}/>
                    </Grid>

                </Grid>


                </Grid>
                    <Grid item xs={4}>
                        {challenges  && <ShowUrl company={challenges[0].company}/>}
                    </Grid>

                </Grid>}
    </div>);
}

export default RenderPage;