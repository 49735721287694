import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useDocumentData} from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import RenderPage from "./RenderPage";
import {CookieStorage} from "cookie-storage";
import {Box} from "@material-ui/core";
const cookieStorage = new CookieStorage();

const useStyles = makeStyles( theme=>({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        background: "#00A4CF",
        color: "white"
    },
    mono: {
        fontFamily: "Roboto Mono, monospace"
    },
    appBarSpacer: theme.mixins.toolbar,
}));

function ConnectPage({id}) {
    const classes = useStyles();
    const [value, loading, error] = useDocumentData(firebase.firestore().doc(`tv/${id}`), {"idField": "id"});
    if (error) {
        console.error(error);
        window.location.reload();
    }

    if (!loading && !value) {
        cookieStorage.removeItem("code");
        window.location.reload();
    }

    return (value && value.company===null && <div className={classes.root}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.content}
        >
                <Typography variant="h2" align="center">Connect you TV</Typography>
                <Box pt={4} pb={4}>
                <Typography align="center">Type the code below into your PlusCulture admin console to connect this tv.</Typography>
                </Box>
                <Typography variant="h1" align="center" className={classes.mono}>{value.code}</Typography>
        </Grid>
    </div>) || (value && value.company!==null && <RenderPage spec={value}/>) || null;
}

export default ConnectPage;