import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import firebase from "firebase/app";
import {CookieStorage} from "cookie-storage";
import ConnectPage from "./ConnectPage";

const cookieStorage = new CookieStorage();

const useStyles = makeStyles( theme=>({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
}));

function makeid() {
    let result = '';
    const characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < 5; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const createNewCode = async () => {
    const content = {
        company: null,
        code: makeid()
    };
    const doc = await firebase.firestore().collection(`tv`).add(content);
    content.id = doc.id;
    return content;
};

function DashboardPage() {
    const classes = useStyles();
    const [code, setCode] = useState(cookieStorage.getItem("code"));

    useEffect(() => {
        if (!code) {
            createNewCode().then((code)=>{
                cookieStorage.setItem("code", code.id);
                setCode(cookieStorage.getItem("code"));
            });
        }
    },[code]);

    if (code) {
        return (<ConnectPage id={code}/>);
    } else {
        return (<div className={classes.root}>

        </div>);
    }
}

export default DashboardPage;