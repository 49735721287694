import React, {Fragment, useEffect} from "react";
import Container from "@material-ui/core/Container";
import firebase from "firebase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import moment from "moment";
import PLUS_COLORS from "./PlusColors";

const getColorIndexFromTeam = (teams,name) => {
    return teams.sort().indexOf(name);
};

const getTimelineInDays = (measurements) => {
    const days = {};
    measurements.forEach((m)=>{
        const date = moment(m.time.toDate()).startOf('day');
        days[date.valueOf()] = {date: date.valueOf(), value: days[date.valueOf()]?days[date.valueOf()].value+1:1};
    });
    const values =Object.values(days);
    values.sort((a,b)=>a.date-b.date);
    return values;
};

function ScoreTimeline({id}) {
    const scoresQuery = firebase.firestore().collection(`challenges`).doc(id).collection('score');
    const teamsQuery = firebase.firestore().collection(`challenges`).doc(id).collection('teams');

    const [data,setData] = React.useState(null);

    //const scores = null;
    useEffect(()=>{
        const userScores = new Map();
        const teams = new Map();
        const updateGraph = () => {
            if (userScores.size===0||teams.size===0) return;
            const results = new Map();
            teams.forEach((e) => {
                const teamScore = new Map();

                e.members.filter(member=>userScores.has(member)).forEach((member)=>{
                    userScores.get(member).forEach((time)=>{
                        teamScore.set(time,{time: time, value: (teamScore.get(time)?teamScore.get(time).value:0)+1});
                    });
                });
                Array.from(teamScore.values()).forEach((el)=>{
                    const name = "color"+Array.from(teams.keys()).sort().indexOf(e.name);
                    results.set(el.time,results.get(el.time)?{...results.get(el.time),[name]:el.value}:{date: el.time, [name]:el.value});
                })
            });
            setData(Array.from(results.values()).sort((a,b)=>a.date-b.date));
        }
        scoresQuery.onSnapshot((querySnapshot)=>{
            querySnapshot.docChanges().forEach(function(change) {
                if (change.type==="added") {
                    const user = change.doc.data().user;
                    const time = moment(change.doc.data().time.toDate()).startOf('day').valueOf();
                    userScores.set(user, userScores.get(user) ? [...userScores.get(user), time] : [time]);
                }
            });
            updateGraph();
        });
        teamsQuery.onSnapshot((querySnapshot)=>{
            querySnapshot.docChanges().forEach(function(change) {
                    const name = change.doc.data().name;
                    if (change.type==="removed") {
                        teams.delete(name);
                    } else {
                        teams.set(name, change.doc.data());
                    }
                });
                updateGraph();
            });
    },[id]);

    const start = moment().tz("Europe/Oslo").startOf("Month").valueOf();
    const end = moment().tz("Europe/Oslo").endOf("Month").valueOf();

    const ticks = [];

    let m;
    while ((m = moment(start).add(ticks.length,'days').valueOf())<end) {
        ticks.push(m);
    }

    if (data) console.log(data);
    return (<Fragment>
        {data && <ResponsiveContainer  width='100%' aspect={32.0/9.0}><LineChart
            margin={{
                top: 5, right: 30, left: 20, bottom: 5,
            }}
            data={data}>
            <XAxis
                dataKey='date'
                domain={[start, end]}
                scale='time'
                type='number'
                ticks={ticks}
                stroke="white"
                tickFormatter={(date) => moment(date).format('DD')}
            />
            <YAxis stroke="white"/>
            <Line connectNulls type='linear' strokeWidth={3}
                  stroke={PLUS_COLORS[0]}
                  dataKey={"color0"}
            />
            <Line connectNulls type='linear' strokeWidth={3}
                  stroke={PLUS_COLORS[1]}
                  dataKey={"color1"}
            />
            <Line connectNulls type='linear' strokeWidth={3}
                  stroke={PLUS_COLORS[2]}
                  dataKey={"color2"}
            />
            <Line connectNulls type='linear' strokeWidth={3}
                  stroke={PLUS_COLORS[3]}
                  dataKey={"color3"}
            />
            <Line connectNulls type='linear' strokeWidth={3}
                  stroke={PLUS_COLORS[4]}
                  dataKey={"color4"}
            />
            <Line connectNulls type='linear' strokeWidth={3}
                  stroke={PLUS_COLORS[5]}
                  dataKey={"color5"}
            />
        </LineChart></ResponsiveContainer>}</Fragment>);
}

export default ScoreTimeline;