const config = {
    firebase_config: {
        apiKey: "AIzaSyB0MRNQMa_JAiyoHybALamj4h_zGOq6jQM",
        authDomain: "plus-values.firebaseapp.com",
        databaseURL: "https://plus-values.firebaseio.com",
        projectId: "plus-values",
        storageBucket: "plus-values.appspot.com",
        messagingSenderId: "602591998260",
        appId: "1:602591998260:web:043970fe39d102b5097561",
        measurementId: "G-GF7QYBVE1L"
    },
    initial_state: {
        locale: "en"
    }
};


export default config;